<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-01-18 17:23:51
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_AccountNow">
        <MemberTitle memberTitle="开通钱包"></MemberTitle>
        <div class="sld_bankCard_con">
            <div class="bank_top">
                <div>开通钱包</div>
            </div>
            <div class="bank_center">
                <div class="bank_state" v-show="!fail">
                    <div class="step">
                        <span class="line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"></i>
                        <span>1.身份验证</span>
                    </div>
                    <div class="step">
                        <span class="no_line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng no_sel"></i>
                        <span>2.开通钱包绑卡</span>
                    </div>
                    <div class="step">
                        <span class="no_line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng no_sel"></i>
                        <span>3.验证码验证</span>
                    </div>
                </div>
            </div>
            <div class="bank_bottom_Form" v-show="!fail">
                <!-- 绑定银行卡 start -->
                <el-form :model="bankForm" label-width="120px">
                    <el-form-item label="真实姓名:">
                        <el-col :span="8" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入真实姓名" width="120px" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="身份证号:">
                        <el-col :span="8" style="float: none;">
                            <el-input v-model="bankForm.idCard" placeholder="请输入身份证号" width="120px" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <el-col :span="8" style="float: none;">
                            <el-input v-model="bankForm.phone" disabled placeholder="请输入手机号" width="120px" />
                        </el-col>
                    </el-form-item>
                    <div class="next">
                        <div @click="onSubmit">下一步</div>
                    </div>
                </el-form>
                <!-- 绑定银行卡 end -->
                <!-- 打款验证 start -->
                <el-form :model="bankForm" label-width="120px" v-if="isidentity == 2">
                    <el-form-item label="验证码">
                        <el-col :span="8" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入验证码" width="120px" />
                            <div class="timeOut" @click="getTestNum">{{ isFlag == true ? descNum : '获取验证码' }}</div>
                        </el-col>
                    </el-form-item>
                    <div class="next2">
                        <div @click="next(3)">确认提交</div>
                    </div>
                </el-form>
                <!-- 打款验证 end -->
                <el-form :model="bankForm" label-width="120px" v-if="isidentity == 3">
                    <el-form-item label="银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-autocomplete v-model="state" :fetch-suggestions="querySearch"
                                popper-class="my-autocomplete" placeholder="Please input" @select="handleSelect">
                                <template #suffix>
                                    <el-icon class="el-input__icon" @click="handleIconClick">
                                        <edit />
                                    </el-icon>
                                </template>
                                <template #default="{ item }">
                                    <div class="value">{{ item.value }}</div>
                                    <span class="link">{{ item.link }}</span>
                                </template>
                            </el-autocomplete>

                        </el-col>
                    </el-form-item> -->
                    <el-form-item label="开户银行所在省:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="开户银行所在市:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="开户行:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item> -->
                    <el-form-item label="银行卡号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入银行卡号" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入手机号" />
                        </el-col>
                    </el-form-item>
                    <div class="next">
                        <div @click="next(4)">下一步</div>
                    </div>
                </el-form>
            </div>
            <div class="bank_bottom_Form1" v-show="fail">
                <img src="../../../assets/member/empty_goods.png" />
                <div class="info">验证失败</div>
                <div class="info">失败原因：{{tishi}}</div>
                <div class="next">
                    <div @click="again">重新申请</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ElInput, ElButton, ElMessage } from "element-plus";
import MemberTitle from '../../../components/MemberTitle';
import { useRouter, } from 'vue-router';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
export default {
    name: "Recharge",
    components: {
        MemberTitle
    },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance()
        const store = useStore();
        // 银行表单
        const bankForm = ref({
            idCard: '',
            phone: '',
            name: '',
        })
        const router = useRouter()
        const tishi = ref('');
        const next = (num) => {
            isidentity.value = num
            console.log(isidentity.value)
        }
        const fail = ref(false)
        //检查号码是否符合规范，包括长度，类型
        const isCardNo = (data)=>{
            //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
            let reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
            if (reg.test(data) === false) {
                return false;
            }
            return true;
        }

//取身份证前两位,校验省份
const checkProvince = (data)=> {
    let city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",
              31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",
              43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",
              61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
    let province = data.substr(0,2);
    if (city[province] == undefined) {
           return false;
    }
    return true
 }
        const onSubmit = (num) => {
            if(!bankForm.value.name){
                ElMessage.warning('请输入姓名')
                return
            }
            if(!bankForm.value.idCard){
                ElMessage.warning('请输入身份证号')
                return
            }
            //检查号码是否符合规范，包括长度，类型
            if(isCardNo(bankForm.value.idCard) === false){  
                ElMessage.warning("请输入正确的身份证号");
                return false;
            }

            //检查省份
            if(checkProvince(bankForm.value.idCard) === false){  
                ElMessage.warning("身份证号码不对校验省份错误");
                return false;
            }
            if(bankForm.value.phone.length==0){
                ElMessage.warning('请输入手机号')
                return
            }
            let checkMobile = proxy.$checkPhone(bankForm.value.phone);
            //   if (checkMobile !== true) {
            //     ElMessage.warning("请输入正确的手机号");
            //     return false;
            //   }
            proxy.$post('api/shopp/member/personal/open/account', bankForm.value,'json').then(res => {
                if (res.code == 200) {
                    ElMessage.success(res.msg)
                    /* 获取用户信息，并同步信息到vuex */
                    proxy.$get("api/shopp/member/detail",{uscUserId:store.state.memberInfo.uscUserId}).then(res_info => {
                        if (res_info.code == 200) {
                            emit('refreshInfo')
                            store.commit('updateMemberInfo', res_info.data);
                            // localStorage.setItem('memberInfo', res_info.data)
                            proxy.$getLoginCartListData(); //更新购物车数据
                            // closeModal();
                        }
                    });
                    /* ------ end ------ */
                    localStorage.setItem('bankForm', JSON.stringify(bankForm.value))
                    router.push('/member/AccountNow1?mobile='+bankForm.value.phone)
                } else {
                    fail.value = true
                    ElMessage.warning(res.msg)
                    tishi.value = res.msg
                }
            })
        }
        // 获取验证码
        const descNum = ref(120)
        let isFlag = ref(false)
        const getTestNum = () => {
            if (isFlag.value == true) {
                return
            }
            isFlag.value = true
            const timedesc = setInterval(() => {
                descNum.value -= 1
            }, 1000);
            setTimeout(() => {
                isFlag.value = false
                descNum.value = 120
                clearInterval(timedesc)
            }, 6000)

            let params = {}
            console.log(userType)
            params.state = userType

            // params.
            proxy.$post('/v3/member/front/member/bindCheckCorp', bankForm.value).then(res => {
                if (res.code == 200) {
                    ElMessage.success(res.msg)
                    localStorage.setItem('bankForm', bankForm.value)
                } else {
                    ElMessage.warning(res.msg)
                }
            })

        }
        // 重新申请
        const again = () => {
            fail.value = false;
            bankForm.value.idCard='';
            bankForm.value.name='';
            // router.push('/member/openAccount')
        }
        const getInfo=()=>{
            proxy.$get("api/shopp/member/detail",{uscUserId:store.state.memberInfo.uscUserId}).then(res => {
                if (res.code == 200) {
                    bankForm.value.phone = res.data.memberMobile
                    bankForm.value.name = res.data.memberTrueName
                    bankForm.value.idCard = res.data.memberCardNo
                    
                }
            })
        }
        onMounted(() => {
        getInfo()
        });
        return {
            next,
            onSubmit,
            bankForm,
            getTestNum,
            descNum,
            isFlag,
            fail,
            again,
            tishi,
            getInfo,
            isCardNo,
            checkProvince

        };
    }
};
</script>
  
<style lang="scss">
.bank_bottom_Form {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    input[type='number'] {
    -moz-appearance: textfield;

    }
    .el-input__inner{
    line-height: 1px !important;
    }
    .el-cascader {
        width: 100%;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 10px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.bank_bottom_Form {
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        right: 450px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next2 {
        width: 70px;
        background-color: rgba(240, 39, 39, 1);
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        margin: 0 auto;
        position: absolute;
        left: 120px;
        top: 90px;
        cursor: pointer;
    }

    .next {
        width: 286px;
        background-color: $colorMain;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        margin-left: 120px;
        margin-top: 80px;
        cursor: pointer;
    }
}

.bank_bottom_Form1 {
    margin-top: 20px;
    // margin-left: 100px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    .info {
        font-size: 16px;
        margin-top: 20px;
    }

    .next {
        border: 1px solid rgba(187, 187, 187, 1);
        width: 110px;
        height: 50px;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 40px;
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
    }
}

.sld_AccountNow {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 307px;
        color: rgba(30, 119, 240, 1);
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        height: 790px;
        // padding-bottom: 50px;
        padding: 0 20px;

        .bank_top {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1C1C1C;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
}

.bank_center {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .bank_state {
        display: flex;
        align-items: center;
        .step {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 28px;
            background-size: 100% 100%;
            // color: white;
            font-size: 14px;
            margin-right: 20px;

            &:nth-last-of-type(1) {
                margin-right: 0;
            }

            .line {
                width: 70px;
                height: 1px;
                background: #51b953;
                display: inline-block;
            }

            .no_line {
                width: 70px;
                height: 1px;
                display: inline-block;
                background: #eeeeee;
            }

            .sel_line {
                background: #51b953;
            }

            i {
                font-size: 16px;
                color: #33ad36;
                margin: 0 10px 0 20px;
            }

            .no_sel {
                color: #e2e2e2;
            }

            .no_step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            .step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }
    }


    .red_state {
        background-color: $colorMain;
        color: rgba(255, 255, 255, 1);
    }

    .white_state {
        position: relative;
        width: 103px;
        height: 103px;
        line-height: 103px;
        // background-color: rgba(240, 39, 39, 1);
        // color: rgba(255, 255, 255, 1);
        // color: black;
        font-size: 20px;
        text-align: center;
        font-family: Roboto;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 1);

        span {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: 14px;
            color: black;
            width: 100px;
        }
    }

}
</style>